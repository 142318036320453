import { apiCall } from '../axios';
import { API_CONSTANTS } from '../../services/apiConstants';
import { ISettlementReport } from './interface/ISettlementReport';
import { ApiCallRequestTypes } from '../../utils/constants';

const fetchSettlementReport = async ({
    startDate,
    endDate,
}: {
    startDate: string;
    endDate: string;
}): Promise<ISettlementReport[]> => {
    const response = await apiCall(
        'get',
        `${API_CONSTANTS.reports}${API_CONSTANTS.settlements}?startDate=${startDate}&endDate=${endDate}`,
        {},
        true,
    );
    return response.data;
};

const fetchCommissionReport = async ({
    startDate,
    endDate,
}: {
    startDate: string;
    endDate: string;
}): Promise<Blob> => {
    const response = await apiCall(
        'get',
        `${API_CONSTANTS.reports}/${startDate}/${endDate}`,
        {},
        true,
        true,
        false,
        ApiCallRequestTypes.excelFormat,
    );
    return response.data;
};

const fetchDeathLossReport = async ({
    startDate,
    endDate,
}: {
    startDate: string;
    endDate: string;
}): Promise<Blob> => {
    const response = await apiCall(
        'get',
        `${API_CONSTANTS.reports}${API_CONSTANTS.deathLoss}/${startDate}/${endDate}`,
        {},
        true,
        true,
        false,
        ApiCallRequestTypes.excelFormat,
    );
    return response.data;
};

export const reportApi = {
    fetchSettlementReport,
    fetchCommissionReport,
    fetchDeathLossReport,
};
