export const AutoCloseTimeoutConstants = {
    toasterAutoClose: 5000,
    axiosTimeOut: 80000,
};

export const DISPLAY_NAME_LENGTH_LIMIT = 14;
export const DISPLAY_FIELD_LABEL_NAME_LENGTH_LIMIT = 15;
export const DISPLAY_LABEL_LENGTH_LIMIT = 50;
export const DISPLAY_LOCATION_NAME_LENGTH_LIMIT = 22;
export const DISPLAY_NAME_MIN_LENGTH_LIMIT = 8;
export const DESTINATION_NAME_LENGTH_LIMIT = 12;
export const MULTIPLE_CONTACT_EMAIL_LENGTH_LIMIT = 52;

export * from './styles';
export * from './timePicker';
export * from './documentTypes';
export * from './filePickerExtensions';
export * from './stateAbbreviations';
export * from './driverTypes';
export * from './attachmentTypes';
export * from './scrollTypes';
export * from './additionalCharges';
export * from './rateTypeDropdown';
export * from './locationModalTypes';
export * from './cargoDetailsTypes';
export * from './associateTypes';
export * from './userRoles';
export * from './profilePictureType';
export * from './driverStatusTypes';
export * from './shipmentStatusTypes';
export * from './documentUsageTypes';
export * from './calculationActionTypes';
export * from './breakdownDriverTypes';
export * from './dashboardReport';
export * from './dateInputTypes';
export * from './featureTypes';
export * from './ownerOperatorTypes';
export * from './apiCallRequestTypes';
export * from './driverLoadChargeTypes';
