import { combineReducers } from '@reduxjs/toolkit';
import RescueDriversReducer from '../common/BreakdownDriverModal/reducer/RescueDriverReducer';
import CommonReducers from '../common/CommonReducers';
import AssociateDetailReducer from '../common/associate/reducers/AssociateDetailReducer';
import PinDropsReducer from '../common/associate/reducers/PinDropDetailsReducer';
import TruckingCompaniesReducer from '../common/truckingCompanies/reducers/TruckingCompaniesReducer';
import CargoDetailsReducer from '../components/CargoDetails/reducer';
import CustomerDeatilReducer from '../components/CustomerDetail/reducers/customerDetail';
import AddressAutocompleteReducer from '../components/Maps/AddressAutocomplete/reducer';
import MapsReducer from '../components/Maps/MapWithMarkers/reducer';
import ShipmentHistoryReducer from '../components/ShipmentHistory/reducer/fetchShipmentHistory';
import TagGroupReducer from '../components/TagGroup/TagGroupReducer';
import ShipmentReducer from '../pages/BuildShipment/reducer';
import CustomerListReducer from '../pages/Customer/reducers/CustomerListReducer';
import UpdateCustomerDetailReducer from '../pages/Customer/reducers/UpdateCustomerDetailReducer';
import DashboardReportsReducer from '../pages/Dashboard/reducers/DashboardReportsReducer';
import DashboardStatsReducer from '../pages/Dashboard/reducers/DashboardStatsReducer';
import HighlightedDriversReducer from '../pages/Dashboard/reducers/HighlightedDriversReducer';
import ReadyForBillingShipmentsReducer from '../pages/Dashboard/reducers/ReadyForBillingShipmentsReducer';
import UpcomingShipmentsReducer from '../pages/Dashboard/reducers/UpcomingShipmentReducer';
import DriverDetailReducer from '../pages/Driver/DriverDetails/reducers/driverDetails';
import UpdateDriverDetailsReducer from '../pages/Driver/DriverDetails/reducers/updateDriverDetails';
import DriverReducer from '../pages/Driver/NewDriverModal/reducers/DriverReducer';
import DriverListReducer from '../pages/Driver/reducers/DriverListReducer';
import InvoicePreviewReducer from '../pages/LoadBoard/components/ShipmentDetails/components/Invoice/reducers/InvoicePreviewReducer';
import invoiceReducer from '../pages/LoadBoard/components/ShipmentDetails/components/Invoice/reducers/InvoiceReducer';
import createDriverLoadsReducer from '../pages/LoadBoard/components/ShipmentDetails/reducers/createDriverLoadsReducer';
import DraftShipmentReducer from '../pages/LoadBoard/components/ShipmentDetails/reducers/draftShipmentDetailsReducer';
import LoadBoardReducer from '../pages/LoadBoard/reducer';
import AllDriversReducer from '../pages/LoadBoard/reducers/AllDriversReducer';
import AlternateDriversReducer from '../pages/LoadBoard/reducers/AlternateDriversReducer';
import SuggestedDriversReducer from '../pages/LoadBoard/reducers/SuggestedDriversReducer';
import NavbarReducer from '../pages/Navbar/reducer';
import CommissionReportReducer from '../pages/Reports/reducers/fetchCommissionReport';
import SettlementReportReducer from '../pages/Reports/reducers/fetchSettlementReport';
import TrailerListReducer from '../pages/Reports/reducers/fetchTrailerList';
import UserReducer from '../pages/Settings/components/CompanyAndUsers/reducers/usersListReducer';
import SettingsAttachmentReducer from '../pages/Settings/reducers/SettingsAttachmentReducer';
import SettingsReducer from '../pages/Settings/reducers/settingsReducer';
import AuthReducer from '../services/auth/reducer';
import TrailerDetailReducer from '../pages/Reports/reducers/fetchTrailerDetail';
import DeathLossReportReducer from '../pages/Reports/reducers/fetchDeathLossReport';

export const rootReducer = combineReducers({
    common: CommonReducers,
    auth: AuthReducer,
    navbar: NavbarReducer,
    maps: MapsReducer,
    addressAutocomplete: AddressAutocompleteReducer,
    cargo: CargoDetailsReducer,
    dashboardReports: DashboardReportsReducer,
    dashboardStats: DashboardStatsReducer,
    upcomingShipments: UpcomingShipmentsReducer,
    readyForBillingShipments: ReadyForBillingShipmentsReducer,
    highlightedDrivers: HighlightedDriversReducer,
    shipment: ShipmentReducer,
    loadBoard: LoadBoardReducer,
    tagGroups: TagGroupReducer,
    allDrivers: AllDriversReducer,
    suggestedDrivers: SuggestedDriversReducer,
    alternateDrivers: AlternateDriversReducer,
    draftShipment: DraftShipmentReducer,
    createDriverLoads: createDriverLoadsReducer,
    driver: DriverReducer,
    truckingCompanies: TruckingCompaniesReducer,
    driverList: DriverListReducer,
    driverDetail: DriverDetailReducer,
    updateDriverDetails: UpdateDriverDetailsReducer,
    associateDetails: AssociateDetailReducer,
    customerList: CustomerListReducer,
    customerDetail: CustomerDeatilReducer,
    shipmentHistory: ShipmentHistoryReducer,
    settingsAttachment: SettingsAttachmentReducer,
    settingsDetail: SettingsReducer,
    invoiceReducer: invoiceReducer,
    rescueDrivers: RescueDriversReducer,
    usersList: UserReducer,
    pinDrops: PinDropsReducer,
    settlementReport: SettlementReportReducer,
    invoicePreview: InvoicePreviewReducer,
    updateCustomerDetail: UpdateCustomerDetailReducer,
    commissionReport: CommissionReportReducer,
    deathLossReport: DeathLossReportReducer,
    trailerList: TrailerListReducer,
    trailerDetail: TrailerDetailReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
