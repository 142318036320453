import { ISettingsDetails } from './interfaces/ISettingsDetails';

export const INITIAL_SETTINGS_DETAILS: ISettingsDetails = {
    brokerFeeType: 'Fixed',
    brokerFeeAmount: '',
    companyName: '',
    emailAddress: '',
    phoneNumber: '',
    faxNumber: '',
    dispatchSupportNumber: '',
    companyAddress: '',
    city: '',
    state: '',
    zipCode: '',
    companyLogo: '',
    baseSettingId: 0,
    settlementReportBatchNumber: '',
    isGreetingMessageIncluded: false,
    greetingMessage: '',
    isOrderNumberIncluded: false,
    isDateIncluded: false,
    isTimeFormat12Hr: false,
    isPinDropNameIncluded: false,
    isLocationInstructionIncluded: false,
    isEstimatedWeightIncluded: false,
    isEstimatedHeadCountIncluded: false,
    isCommodityIncluded: false,
    isDriverInstructionIncluded: false,
    isEndingMessageIncluded: false,
    endingMessage: '',
};

export const BROKER_FEE_TYPE = 'BrokerFeeType';
export const BROKER_FEE_AMOUNT = 'BrokerFeeAmount';
export const COMPANY_NAME = 'CompanyName';
export const COMPANY_ADDRESS = 'CompanyAddress';
export const CITY = 'City';
export const STATE = 'State';
export const ZIP_CODE = 'ZipCode';
export const PHONE_NUMBER = 'PhoneNumber';
export const DISPATCH_SUPPORT_NUMBER = 'DispatchSupportNumber';
export const FAX_NUMBER = 'FaxNumber';
export const EMAIL_ADDRESS = 'EmailAddress';
export const COMPANY_LOGO = 'CompanyLogo';
export const SETTLEMENT_REPORT_BATCH_NUMBER = 'SettlementReportBatchNumber';
export const IS_GREETING_MESSAGE_INCLUDED = 'IsIncludeGreetingMessage';
export const GREETING_MESSAGE = 'GreetingMessage';
export const IS_ORDER_NUMBER_INCLUDED = 'IsIncludeOrderNumber';
export const IS_DATE_INCLUDED = 'IsIncludeDate';
export const IS_TIME_FORMAT_12_HR = 'Is12HrTimeDisplayFormat';
export const IS_PIN_DROP_NAME_INCLUDED = 'IsIncludePinDropName';
export const IS_LOCATION_INSTRUCTION_INCLUDED = 'IsIncludeLocationInstructions';
export const IS_ESTIMATED_WEIGHT_INCLUDED = 'IsIncludeEstimatedWeight';
export const IS_ESTIMATED_HEAD_COUNT_INCLUDED = 'IsIncludeHeadCount';
export const IS_COMMODITY_INCLUDED = 'IsIncludeCommodity';
export const IS_DRIVER_INSTRUCTION_INCLUDED = 'IsIncludeDriverInstructions';
export const IS_ENDING_MESSAGE_INCLUDED = 'IsIncludeEndingMessage';
export const ENDING_MESSAGE = 'EndingMessage';

export const settingConstants = {
    tabs: {
        companyAndUserTab: 'Settings:companyAndUsers',
        brokerAndDriverTab: 'Settings:generalPreferences',
        communicationAndInvoice: 'Settings:communicationAndInvoices',
    },
};
