import { ISetting } from '../../api/settings/interface/ISetting';
import { settingsApi } from '../../api/settings/settingsApi';
import {
    BROKER_FEE_AMOUNT,
    BROKER_FEE_TYPE,
    CITY,
    COMPANY_ADDRESS,
    COMPANY_LOGO,
    COMPANY_NAME,
    DISPATCH_SUPPORT_NUMBER,
    EMAIL_ADDRESS,
    FAX_NUMBER,
    PHONE_NUMBER,
    SETTLEMENT_REPORT_BATCH_NUMBER,
    STATE,
    ZIP_CODE,
    IS_GREETING_MESSAGE_INCLUDED,
    IS_ORDER_NUMBER_INCLUDED,
    IS_DATE_INCLUDED,
    IS_TIME_FORMAT_12_HR,
    IS_PIN_DROP_NAME_INCLUDED,
    IS_LOCATION_INSTRUCTION_INCLUDED,
    IS_ESTIMATED_WEIGHT_INCLUDED,
    IS_ESTIMATED_HEAD_COUNT_INCLUDED,
    IS_COMMODITY_INCLUDED,
    IS_DRIVER_INSTRUCTION_INCLUDED,
    IS_ENDING_MESSAGE_INCLUDED,
    ENDING_MESSAGE,
    GREETING_MESSAGE,
} from './constants';
import { ISettingsDetails } from './interfaces/ISettingsDetails';

export interface ISettingUtils {
    getSettingDetails: (settings: ISetting[]) => ISettingsDetails;
}

export class SettingUtils implements ISettingUtils {
    getSettingDetails = (settings: ISetting[]): ISettingsDetails => {
        const getSettingValue = (settingName: string): string => {
            const setting = settings.find(
                setting => setting.name === settingName,
            );
            return setting?.value !== null ? String(setting?.value) : '';
        };
        const getBooleanSettingValue = (settingName: string): boolean => {
            const setting = settings.find(
                setting => setting.name === settingName,
            );
            return setting?.value === true;
        };
        const companyLogoSetting = settings.find(
            setting => setting.name === COMPANY_LOGO,
        );
        const brokerFeeType = getSettingValue(BROKER_FEE_TYPE);
        const brokerFeeAmount = getSettingValue(BROKER_FEE_AMOUNT);
        const companyName = getSettingValue(COMPANY_NAME);
        const companyAddress = getSettingValue(COMPANY_ADDRESS);
        const city = getSettingValue(CITY);
        const state = getSettingValue(STATE);
        const zipCode = getSettingValue(ZIP_CODE);
        const phoneNumber = getSettingValue(PHONE_NUMBER);
        const dispatchSupportNumber = getSettingValue(DISPATCH_SUPPORT_NUMBER);
        const faxNumber = getSettingValue(FAX_NUMBER);
        const emailAddress = getSettingValue(EMAIL_ADDRESS);
        const companyLogo = getSettingValue(COMPANY_LOGO);
        const baseSettingId = companyLogoSetting?.baseSettingId;
        const settlementReportBatchNumber = getSettingValue(
            SETTLEMENT_REPORT_BATCH_NUMBER,
        );
        const greetingMessage = getSettingValue(GREETING_MESSAGE);
        const endingMessage = getSettingValue(ENDING_MESSAGE);
        const isGreetingMessageIncluded = getBooleanSettingValue(
            IS_GREETING_MESSAGE_INCLUDED,
        );
        const isEndingMessageIncluded = getBooleanSettingValue(
            IS_ENDING_MESSAGE_INCLUDED,
        );
        const isDriverInstructionIncluded = getBooleanSettingValue(
            IS_DRIVER_INSTRUCTION_INCLUDED,
        );
        const isOrderNumberIncluded = getBooleanSettingValue(
            IS_ORDER_NUMBER_INCLUDED,
        );
        const isDateIncluded = getBooleanSettingValue(IS_DATE_INCLUDED);
        const isTimeFormat12Hr = getBooleanSettingValue(IS_TIME_FORMAT_12_HR);
        const isPinDropNameIncluded = getBooleanSettingValue(
            IS_PIN_DROP_NAME_INCLUDED,
        );
        const isLocationInstructionIncluded = getBooleanSettingValue(
            IS_LOCATION_INSTRUCTION_INCLUDED,
        );
        const isEstimatedWeightIncluded = getBooleanSettingValue(
            IS_ESTIMATED_WEIGHT_INCLUDED,
        );
        const isEstimatedHeadCountIncluded = getBooleanSettingValue(
            IS_ESTIMATED_HEAD_COUNT_INCLUDED,
        );
        const isCommodityIncluded = getBooleanSettingValue(
            IS_COMMODITY_INCLUDED,
        );
        return {
            brokerFeeType,
            brokerFeeAmount,
            companyName,
            emailAddress,
            phoneNumber,
            faxNumber,
            dispatchSupportNumber,
            companyAddress,
            city,
            state,
            zipCode,
            companyLogo,
            baseSettingId,
            settlementReportBatchNumber,
            greetingMessage,
            endingMessage,
            isGreetingMessageIncluded,
            isEndingMessageIncluded,
            isDriverInstructionIncluded,
            isOrderNumberIncluded,
            isDateIncluded,
            isTimeFormat12Hr,
            isPinDropNameIncluded,
            isLocationInstructionIncluded,
            isEstimatedWeightIncluded,
            isEstimatedHeadCountIncluded,
            isCommodityIncluded,
        };
    };

    async fetchTenantSettings(): Promise<ISettingsDetails | undefined> {
        try {
            const settings = await settingsApi.fetchSettings();
            const settingsDetail = this.getSettingDetails(settings);

            if (settingsDetail.companyLogo) {
                const companyLogoDetails = settingsDetail.companyLogo;
                const formattedCompanyLogoDetails =
                    JSON.parse(companyLogoDetails);
                const attachmentId = formattedCompanyLogoDetails.AttachmentId;
                const accessToken = formattedCompanyLogoDetails.AccessToken;

                const attachmentResponse =
                    await settingsApi.fetchCompanyProfilePicture(
                        settingsDetail.baseSettingId || 0,
                        accessToken,
                        attachmentId,
                    );

                const logoUrl = URL.createObjectURL(attachmentResponse.data);
                if (settingsDetail.companyLogo !== logoUrl) {
                    settingsDetail.companyLogo = logoUrl;
                }
                return settingsDetail;
            } else {
                return settingsDetail;
            }
        } catch {
            return undefined;
        }
    }
}
