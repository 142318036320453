export const API_CONSTANTS = {
    auth: '/auth',
    refresh: '/refresh',
    users: '/Users',
    password: '/password',
    reset: '/reset',
    transient: '/transient',
    dashboard: '/dashboard',
    associates: '/associates',
    customers: '/customers',
    brokers: '/brokers',
    loads: '/loads',
    drivers: '/drivers',
    attachments: '/attachments',
    shipments: '/shipments',
    dispatch: '/dispatch',
    pindrops: '/pindrops',
    rates: '/rates',
    commodity: '/commodity',
    taggroups: '/taggroups',
    truckingcompany: '/truckingcompany',
    setting: '/setting',
    invoice: '/invoice',
    tenant: '/tenant',
    signUp: '/signupnewtenant',
    verifySubscription: '/verifysubscription',
    breakdown: '/breakdown',
    associate: '/associate',
    settlements: '/settlements',
    reports: '/reports',
    invoicePreview: '/invoice-preview',
    trailer: '/trailer',
    reassigntrailer: '/reassigntrailer',
    deathLoss: '/deathLoss',
    driver: '/driver',
};
